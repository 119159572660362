import { useLayoutEffect, useState } from 'react';

/**  FUNCTION useWindowWidth
 * WATCHES and RETURNS the width of the window
 * (Ignores any user-dragging-resize of the screen until they stop for at least a second)
 * @param {number} timeInMs - REQUIRED - Time (in milliseconds) before the get window size event fires
 * @returns {number} windowWidth in pixels
 */
export const useWindowWidth = (timeInMs) => {
  const [windowWidth, setWindowWidth] = useState(_getWindowWidth());

  useLayoutEffect(() => {
    const delayedHandleResize = _delay(() => {
      setWindowWidth(_getWindowWidth());
    }, timeInMs);

    window.addEventListener('resize', delayedHandleResize);
    return () => window.removeEventListener('resize', delayedHandleResize);
  }, []);

  return windowWidth;
};

/** internal function _getWindowWidth: simply gets the width of the window */
const _getWindowWidth = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

/** internal function _delay: delay hook used for delaying when the detection script runs
 *  so it doesn't run every pixel of screen size or element change...
 */
const _delay = (fn, ms) => {
  let timer;
  return function () {
    clearTimeout(timer);
    const args = [...arguments];
    args.unshift(this);
    timer = setTimeout(fn.bind.apply(fn, args), ms);
  };
};
