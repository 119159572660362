/**
  * Do not edit directly,
  * Generated using script
  */

const theme = {
  "breakpoint": {
    "s": {
      "value": "320",
      "original": {
        "value": "320"
      },
      "name": "breakpointS",
      "attributes": {
        "category": "breakpoint",
        "type": "s"
      },
      "path": [
        "breakpoint",
        "s"
      ]
    },
    "m": {
      "value": "480",
      "original": {
        "value": "480"
      },
      "name": "breakpointM",
      "attributes": {
        "category": "breakpoint",
        "type": "m"
      },
      "path": [
        "breakpoint",
        "m"
      ]
    },
    "l": {
      "value": "768",
      "original": {
        "value": "768"
      },
      "name": "breakpointL",
      "attributes": {
        "category": "breakpoint",
        "type": "l"
      },
      "path": [
        "breakpoint",
        "l"
      ]
    },
    "xl": {
      "value": "1024",
      "original": {
        "value": "1024"
      },
      "name": "breakpointXl",
      "attributes": {
        "category": "breakpoint",
        "type": "xl"
      },
      "path": [
        "breakpoint",
        "xl"
      ]
    }
  },
  "size": {
    "borderRadius": {
      "full": {
        "value": "50%",
        "original": {
          "value": "50%"
        },
        "name": "sizeBorderRadiusFull",
        "attributes": {
          "category": "size",
          "type": "borderRadius",
          "item": "full"
        },
        "path": [
          "size",
          "borderRadius",
          "full"
        ]
      },
      "extraSmall": {
        "value": "2px",
        "original": {
          "value": "2px"
        },
        "name": "sizeBorderRadiusExtraSmall",
        "attributes": {
          "category": "size",
          "type": "borderRadius",
          "item": "extraSmall"
        },
        "path": [
          "size",
          "borderRadius",
          "extraSmall"
        ]
      },
      "small": {
        "value": "4px",
        "original": {
          "value": "4px"
        },
        "name": "sizeBorderRadiusSmall",
        "attributes": {
          "category": "size",
          "type": "borderRadius",
          "item": "small"
        },
        "path": [
          "size",
          "borderRadius",
          "small"
        ]
      },
      "medium": {
        "value": "6px",
        "original": {
          "value": "6px"
        },
        "name": "sizeBorderRadiusMedium",
        "attributes": {
          "category": "size",
          "type": "borderRadius",
          "item": "medium"
        },
        "path": [
          "size",
          "borderRadius",
          "medium"
        ]
      },
      "large": {
        "value": "8px",
        "original": {
          "value": "8px"
        },
        "name": "sizeBorderRadiusLarge",
        "attributes": {
          "category": "size",
          "type": "borderRadius",
          "item": "large"
        },
        "path": [
          "size",
          "borderRadius",
          "large"
        ]
      }
    },
    "spacing": {
      "small": {
        "value": "0.25rem",
        "original": {
          "value": "4px"
        },
        "name": "sizeSpacingSmall",
        "attributes": {
          "category": "size",
          "type": "spacing",
          "item": "small"
        },
        "path": [
          "size",
          "spacing",
          "small"
        ]
      },
      "medium": {
        "value": "0.5rem",
        "original": {
          "value": "8px"
        },
        "name": "sizeSpacingMedium",
        "attributes": {
          "category": "size",
          "type": "spacing",
          "item": "medium"
        },
        "path": [
          "size",
          "spacing",
          "medium"
        ]
      },
      "large": {
        "value": "1rem",
        "original": {
          "value": "16px"
        },
        "name": "sizeSpacingLarge",
        "attributes": {
          "category": "size",
          "type": "spacing",
          "item": "large"
        },
        "path": [
          "size",
          "spacing",
          "large"
        ]
      }
    }
  },
  "transition": {
    "duration": {
      "value": "0.2s",
      "original": {
        "value": "0.2s"
      },
      "name": "transitionDuration",
      "attributes": {
        "category": "transition",
        "type": "duration"
      },
      "path": [
        "transition",
        "duration"
      ]
    },
    "timing": {
      "value": "ease-in-out",
      "original": {
        "value": "ease-in-out"
      },
      "name": "transitionTiming",
      "attributes": {
        "category": "transition",
        "type": "timing"
      },
      "path": [
        "transition",
        "timing"
      ]
    }
  },
  "zIndex": {
    "hidden": {
      "value": -1,
      "original": {
        "value": -1
      },
      "name": "zIndexHidden",
      "attributes": {
        "category": "zIndex",
        "type": "hidden"
      },
      "path": [
        "zIndex",
        "hidden"
      ]
    },
    "buttonOverlay": {
      "value": 0,
      "original": {
        "value": 0
      },
      "name": "zIndexButtonOverlay",
      "attributes": {
        "category": "zIndex",
        "type": "buttonOverlay"
      },
      "path": [
        "zIndex",
        "buttonOverlay"
      ]
    },
    "chipOverlay": {
      "value": 0,
      "original": {
        "value": 0
      },
      "name": "zIndexChipOverlay",
      "attributes": {
        "category": "zIndex",
        "type": "chipOverlay"
      },
      "path": [
        "zIndex",
        "chipOverlay"
      ]
    },
    "globalNavigationFooter": {
      "value": 0,
      "original": {
        "value": 0
      },
      "name": "zIndexGlobalNavigationFooter",
      "attributes": {
        "category": "zIndex",
        "type": "globalNavigationFooter"
      },
      "path": [
        "zIndex",
        "globalNavigationFooter"
      ]
    },
    "tableRow": {
      "value": 0,
      "original": {
        "value": 0
      },
      "name": "zIndexTableRow",
      "attributes": {
        "category": "zIndex",
        "type": "tableRow"
      },
      "path": [
        "zIndex",
        "tableRow"
      ]
    },
    "buttonContent": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexButtonContent",
      "attributes": {
        "category": "zIndex",
        "type": "buttonContent"
      },
      "path": [
        "zIndex",
        "buttonContent"
      ]
    },
    "chipContent": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexChipContent",
      "attributes": {
        "category": "zIndex",
        "type": "chipContent"
      },
      "path": [
        "zIndex",
        "chipContent"
      ]
    },
    "datepickerAnimating": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexDatepickerAnimating",
      "attributes": {
        "category": "zIndex",
        "type": "datepickerAnimating"
      },
      "path": [
        "zIndex",
        "datepickerAnimating"
      ]
    },
    "datepickerNavigation": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexDatepickerNavigation",
      "attributes": {
        "category": "zIndex",
        "type": "datepickerNavigation"
      },
      "path": [
        "zIndex",
        "datepickerNavigation"
      ]
    },
    "datepickerShortcuts": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexDatepickerShortcuts",
      "attributes": {
        "category": "zIndex",
        "type": "datepickerShortcuts"
      },
      "path": [
        "zIndex",
        "datepickerShortcuts"
      ]
    },
    "globalNavigationContextSwitcher": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexGlobalNavigationContextSwitcher",
      "attributes": {
        "category": "zIndex",
        "type": "globalNavigationContextSwitcher"
      },
      "path": [
        "zIndex",
        "globalNavigationContextSwitcher"
      ]
    },
    "tableRowFocused": {
      "value": 1,
      "original": {
        "value": 1
      },
      "name": "zIndexTableRowFocused",
      "attributes": {
        "category": "zIndex",
        "type": "tableRowFocused"
      },
      "path": [
        "zIndex",
        "tableRowFocused"
      ]
    },
    "badge": {
      "value": 2,
      "original": {
        "value": 2
      },
      "name": "zIndexBadge",
      "attributes": {
        "category": "zIndex",
        "type": "badge"
      },
      "path": [
        "zIndex",
        "badge"
      ]
    },
    "datepicker": {
      "value": 2,
      "original": {
        "value": 2
      },
      "name": "zIndexDatepicker",
      "attributes": {
        "category": "zIndex",
        "type": "datepicker"
      },
      "path": [
        "zIndex",
        "datepicker"
      ]
    },
    "dropdownMenu": {
      "value": 2,
      "original": {
        "value": 2
      },
      "name": "zIndexDropdownMenu",
      "attributes": {
        "category": "zIndex",
        "type": "dropdownMenu"
      },
      "path": [
        "zIndex",
        "dropdownMenu"
      ]
    },
    "globalNavigationDropdown": {
      "value": 2,
      "original": {
        "value": 2
      },
      "name": "zIndexGlobalNavigationDropdown",
      "attributes": {
        "category": "zIndex",
        "type": "globalNavigationDropdown"
      },
      "path": [
        "zIndex",
        "globalNavigationDropdown"
      ]
    },
    "globalNavigationHeader": {
      "value": 2,
      "original": {
        "value": 2
      },
      "name": "zIndexGlobalNavigationHeader",
      "attributes": {
        "category": "zIndex",
        "type": "globalNavigationHeader"
      },
      "path": [
        "zIndex",
        "globalNavigationHeader"
      ]
    },
    "simpleTableHeader": {
      "value": 2,
      "original": {
        "value": 2
      },
      "name": "zIndexSimpleTableHeader",
      "attributes": {
        "category": "zIndex",
        "type": "simpleTableHeader"
      },
      "path": [
        "zIndex",
        "simpleTableHeader"
      ]
    },
    "sideSheetOverlay": {
      "value": 3,
      "original": {
        "value": 3
      },
      "name": "zIndexSideSheetOverlay",
      "attributes": {
        "category": "zIndex",
        "type": "sideSheetOverlay"
      },
      "path": [
        "zIndex",
        "sideSheetOverlay"
      ]
    },
    "globalNavigation": {
      "value": 3,
      "original": {
        "value": 3
      },
      "name": "zIndexGlobalNavigation",
      "attributes": {
        "category": "zIndex",
        "type": "globalNavigation"
      },
      "path": [
        "zIndex",
        "globalNavigation"
      ]
    },
    "toast": {
      "value": 3,
      "original": {
        "value": 3
      },
      "name": "zIndexToast",
      "attributes": {
        "category": "zIndex",
        "type": "toast"
      },
      "path": [
        "zIndex",
        "toast"
      ]
    },
    "tooltip": {
      "value": 3,
      "original": {
        "value": 3
      },
      "name": "zIndexTooltip",
      "attributes": {
        "category": "zIndex",
        "type": "tooltip"
      },
      "path": [
        "zIndex",
        "tooltip"
      ]
    },
    "fab": {
      "value": 4,
      "original": {
        "value": 4
      },
      "name": "zIndexFab",
      "attributes": {
        "category": "zIndex",
        "type": "fab"
      },
      "path": [
        "zIndex",
        "fab"
      ]
    },
    "tabsGroup": {
      "value": 4,
      "original": {
        "value": 4
      },
      "name": "zIndexTabsGroup",
      "attributes": {
        "category": "zIndex",
        "type": "tabsGroup"
      },
      "path": [
        "zIndex",
        "tabsGroup"
      ]
    },
    "dialogOverlay": {
      "value": 4,
      "original": {
        "value": 4
      },
      "name": "zIndexDialogOverlay",
      "attributes": {
        "category": "zIndex",
        "type": "dialogOverlay"
      },
      "path": [
        "zIndex",
        "dialogOverlay"
      ]
    },
    "dialog": {
      "value": 5,
      "original": {
        "value": 5
      },
      "name": "zIndexDialog",
      "attributes": {
        "category": "zIndex",
        "type": "dialog"
      },
      "path": [
        "zIndex",
        "dialog"
      ]
    }
  },
  "color": {
    "accent": {
      "50": {
        "value": "#e7f3ff",
        "original": {
          "value": "#e7f3ff"
        },
        "name": "colorAccent50",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "50"
        },
        "path": [
          "color",
          "accent",
          "50"
        ]
      },
      "100": {
        "value": "#dbedff",
        "original": {
          "value": "#dbedff"
        },
        "name": "colorAccent100",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "100"
        },
        "path": [
          "color",
          "accent",
          "100"
        ]
      },
      "200": {
        "value": "#c3e1ff",
        "original": {
          "value": "#c3e1ff"
        },
        "name": "colorAccent200",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "200"
        },
        "path": [
          "color",
          "accent",
          "200"
        ]
      },
      "300": {
        "value": "#88c4ff",
        "original": {
          "value": "#88c4ff"
        },
        "name": "colorAccent300",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "300"
        },
        "path": [
          "color",
          "accent",
          "300"
        ]
      },
      "400": {
        "value": "#359bff",
        "original": {
          "value": "#359bff"
        },
        "name": "colorAccent400",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "400"
        },
        "path": [
          "color",
          "accent",
          "400"
        ]
      },
      "500": {
        "value": "#128aff",
        "original": {
          "value": "#128aff"
        },
        "name": "colorAccent500",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "500"
        },
        "path": [
          "color",
          "accent",
          "500"
        ]
      },
      "600": {
        "value": "#1279df",
        "original": {
          "value": "#1279df"
        },
        "name": "colorAccent600",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "600"
        },
        "path": [
          "color",
          "accent",
          "600"
        ]
      },
      "700": {
        "value": "#126ec8",
        "original": {
          "value": "#126ec8"
        },
        "name": "colorAccent700",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "700"
        },
        "path": [
          "color",
          "accent",
          "700"
        ]
      },
      "800": {
        "value": "#1262b3",
        "original": {
          "value": "#1262b3"
        },
        "name": "colorAccent800",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "800"
        },
        "path": [
          "color",
          "accent",
          "800"
        ]
      },
      "900": {
        "value": "#135292",
        "original": {
          "value": "#135292"
        },
        "name": "colorAccent900",
        "attributes": {
          "category": "color",
          "type": "accent",
          "item": "900"
        },
        "path": [
          "color",
          "accent",
          "900"
        ]
      }
    },
    "additional": {
      "black": {
        "value": "#000000",
        "original": {
          "value": "#000"
        },
        "name": "colorAdditionalBlack",
        "attributes": {
          "category": "color",
          "type": "additional",
          "item": "black"
        },
        "path": [
          "color",
          "additional",
          "black"
        ]
      },
      "brand": {
        "value": "#82c600",
        "original": {
          "value": "#82c600"
        },
        "name": "colorAdditionalBrand",
        "attributes": {
          "category": "color",
          "type": "additional",
          "item": "brand"
        },
        "path": [
          "color",
          "additional",
          "brand"
        ]
      },
      "dark": {
        "value": "#151b25",
        "original": {
          "value": "#151b25"
        },
        "name": "colorAdditionalDark",
        "attributes": {
          "category": "color",
          "type": "additional",
          "item": "dark"
        },
        "path": [
          "color",
          "additional",
          "dark"
        ]
      },
      "light": {
        "value": "#ffffff",
        "original": {
          "value": "#fff"
        },
        "name": "colorAdditionalLight",
        "attributes": {
          "category": "color",
          "type": "additional",
          "item": "light"
        },
        "path": [
          "color",
          "additional",
          "light"
        ]
      }
    },
    "gray": {
      "50": {
        "value": "#f3f3f3",
        "original": {
          "value": "#f3f3f3"
        },
        "name": "colorGray50",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "50"
        },
        "path": [
          "color",
          "gray",
          "50"
        ]
      },
      "100": {
        "value": "#e8e8e9",
        "original": {
          "value": "#e8e8e9"
        },
        "name": "colorGray100",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "100"
        },
        "path": [
          "color",
          "gray",
          "100"
        ]
      },
      "200": {
        "value": "#dcddde",
        "original": {
          "value": "#dcddde"
        },
        "name": "colorGray200",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "200"
        },
        "path": [
          "color",
          "gray",
          "200"
        ]
      },
      "300": {
        "value": "#c4c5c8",
        "original": {
          "value": "#c4c5c8"
        },
        "name": "colorGray300",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "300"
        },
        "path": [
          "color",
          "gray",
          "300"
        ]
      },
      "400": {
        "value": "#a1a3a7",
        "original": {
          "value": "#a1a3a7"
        },
        "name": "colorGray400",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "400"
        },
        "path": [
          "color",
          "gray",
          "400"
        ]
      },
      "500": {
        "value": "#8a8d92",
        "original": {
          "value": "#8a8d92"
        },
        "name": "colorGray500",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "500"
        },
        "path": [
          "color",
          "gray",
          "500"
        ]
      },
      "600": {
        "value": "#72767c",
        "original": {
          "value": "#72767c"
        },
        "name": "colorGray600",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "600"
        },
        "path": [
          "color",
          "gray",
          "600"
        ]
      },
      "700": {
        "value": "#5b5f66",
        "original": {
          "value": "#5b5f66"
        },
        "name": "colorGray700",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "700"
        },
        "path": [
          "color",
          "gray",
          "700"
        ]
      },
      "800": {
        "value": "#373c45",
        "original": {
          "value": "#373c45"
        },
        "name": "colorGray800",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "800"
        },
        "path": [
          "color",
          "gray",
          "800"
        ]
      },
      "900": {
        "value": "#2c323b",
        "original": {
          "value": "#2c323b"
        },
        "name": "colorGray900",
        "attributes": {
          "category": "color",
          "type": "gray",
          "item": "900"
        },
        "path": [
          "color",
          "gray",
          "900"
        ]
      }
    },
    "primary": {
      "50": {
        "value": "#e6f0fe",
        "original": {
          "value": "#e6f0fe"
        },
        "name": "colorPrimary50",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "50"
        },
        "path": [
          "color",
          "primary",
          "50"
        ]
      },
      "100": {
        "value": "#d9e9fe",
        "original": {
          "value": "#d9e9fe"
        },
        "name": "colorPrimary100",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "100"
        },
        "path": [
          "color",
          "primary",
          "100"
        ]
      },
      "200": {
        "value": "#bfdafd",
        "original": {
          "value": "#bfdafd"
        },
        "name": "colorPrimary200",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "200"
        },
        "path": [
          "color",
          "primary",
          "200"
        ]
      },
      "300": {
        "value": "#80b5fc",
        "original": {
          "value": "#80b5fc"
        },
        "name": "colorPrimary300",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "300"
        },
        "path": [
          "color",
          "primary",
          "300"
        ]
      },
      "400": {
        "value": "#2681fa",
        "original": {
          "value": "#2681fa"
        },
        "name": "colorPrimary400",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "400"
        },
        "path": [
          "color",
          "primary",
          "400"
        ]
      },
      "500": {
        "value": "#006cfa",
        "original": {
          "value": "#006cfa"
        },
        "name": "colorPrimary500",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "500"
        },
        "path": [
          "color",
          "primary",
          "500"
        ]
      },
      "600": {
        "value": "#035fda",
        "original": {
          "value": "#035fda"
        },
        "name": "colorPrimary600",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "600"
        },
        "path": [
          "color",
          "primary",
          "600"
        ]
      },
      "700": {
        "value": "#0557c4",
        "original": {
          "value": "#0557c4"
        },
        "name": "colorPrimary700",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "700"
        },
        "path": [
          "color",
          "primary",
          "700"
        ]
      },
      "800": {
        "value": "#074faf",
        "original": {
          "value": "#074faf"
        },
        "name": "colorPrimary800",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "800"
        },
        "path": [
          "color",
          "primary",
          "800"
        ]
      },
      "900": {
        "value": "#0b438f",
        "original": {
          "value": "#0b438f"
        },
        "name": "colorPrimary900",
        "attributes": {
          "category": "color",
          "type": "primary",
          "item": "900"
        },
        "path": [
          "color",
          "primary",
          "900"
        ]
      }
    },
    "secondary": {
      "cherryBombRed": {
        "50": {
          "value": "#fceced",
          "original": {
            "value": "#fceced"
          },
          "name": "colorSecondaryCherryBombRed50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "50"
          ]
        },
        "100": {
          "value": "#fae2e5",
          "original": {
            "value": "#fae2e5"
          },
          "name": "colorSecondaryCherryBombRed100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "100"
          ]
        },
        "200": {
          "value": "#f7ced4",
          "original": {
            "value": "#f7ced4"
          },
          "name": "colorSecondaryCherryBombRed200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "200"
          ]
        },
        "300": {
          "value": "#f09ca6",
          "original": {
            "value": "#f09ca6"
          },
          "name": "colorSecondaryCherryBombRed300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "300"
          ]
        },
        "400": {
          "value": "#e54b61",
          "original": {
            "value": "#e54b61"
          },
          "name": "colorSecondaryCherryBombRed400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "400"
          ]
        },
        "500": {
          "value": "#da253c",
          "original": {
            "value": "#da253c"
          },
          "name": "colorSecondaryCherryBombRed500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "500"
          ]
        },
        "600": {
          "value": "#c5213d",
          "original": {
            "value": "#c5213d"
          },
          "name": "colorSecondaryCherryBombRed600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "600"
          ]
        },
        "700": {
          "value": "#b32139",
          "original": {
            "value": "#b32139"
          },
          "name": "colorSecondaryCherryBombRed700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "700"
          ]
        },
        "800": {
          "value": "#a02139",
          "original": {
            "value": "#a02139"
          },
          "name": "colorSecondaryCherryBombRed800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "800"
          ]
        },
        "900": {
          "value": "#832135",
          "original": {
            "value": "#832135"
          },
          "name": "colorSecondaryCherryBombRed900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cherryBombRed",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "cherryBombRed",
            "900"
          ]
        }
      },
      "fireballOrange": {
        "50": {
          "value": "#fef3eb",
          "original": {
            "value": "#fef3eb"
          },
          "name": "colorSecondaryFireballOrange50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "50"
          ]
        },
        "100": {
          "value": "#feede1",
          "original": {
            "value": "#feede1"
          },
          "name": "colorSecondaryFireballOrange100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "100"
          ]
        },
        "200": {
          "value": "#ffe1ce",
          "original": {
            "value": "#ffe1ce"
          },
          "name": "colorSecondaryFireballOrange200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "200"
          ]
        },
        "300": {
          "value": "#fec39b",
          "original": {
            "value": "#fec39b"
          },
          "name": "colorSecondaryFireballOrange300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "300"
          ]
        },
        "400": {
          "value": "#fe9649",
          "original": {
            "value": "#fe9649"
          },
          "name": "colorSecondaryFireballOrange400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "400"
          ]
        },
        "500": {
          "value": "#fc7a28",
          "original": {
            "value": "#fc7a28"
          },
          "name": "colorSecondaryFireballOrange500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "500"
          ]
        },
        "600": {
          "value": "#df741e",
          "original": {
            "value": "#df741e"
          },
          "name": "colorSecondaryFireballOrange600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "600"
          ]
        },
        "700": {
          "value": "#c96b1e",
          "original": {
            "value": "#c96b1e"
          },
          "name": "colorSecondaryFireballOrange700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "700"
          ]
        },
        "800": {
          "value": "#b56123",
          "original": {
            "value": "#b56123"
          },
          "name": "colorSecondaryFireballOrange800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "800"
          ]
        },
        "900": {
          "value": "#945325",
          "original": {
            "value": "#945325"
          },
          "name": "colorSecondaryFireballOrange900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "fireballOrange",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "fireballOrange",
            "900"
          ]
        }
      },
      "bubbleGumPink": {
        "50": {
          "value": "#fdf0f5",
          "original": {
            "value": "#fdf0f5"
          },
          "name": "colorSecondaryBubbleGumPink50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "50"
          ]
        },
        "100": {
          "value": "#fde8f1",
          "original": {
            "value": "#fde8f1"
          },
          "name": "colorSecondaryBubbleGumPink100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "100"
          ]
        },
        "200": {
          "value": "#fbd8e7",
          "original": {
            "value": "#fbd8e7"
          },
          "name": "colorSecondaryBubbleGumPink200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "200"
          ]
        },
        "300": {
          "value": "#f8b2d0",
          "original": {
            "value": "#f8b2d0"
          },
          "name": "colorSecondaryBubbleGumPink300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "300"
          ]
        },
        "400": {
          "value": "#f376ae",
          "original": {
            "value": "#f376ae"
          },
          "name": "colorSecondaryBubbleGumPink400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "400"
          ]
        },
        "500": {
          "value": "#ee5396",
          "original": {
            "value": "#ee5396"
          },
          "name": "colorSecondaryBubbleGumPink500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "500"
          ]
        },
        "600": {
          "value": "#d45290",
          "original": {
            "value": "#d45290"
          },
          "name": "colorSecondaryBubbleGumPink600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "600"
          ]
        },
        "700": {
          "value": "#c04d84",
          "original": {
            "value": "#c04d84"
          },
          "name": "colorSecondaryBubbleGumPink700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "700"
          ]
        },
        "800": {
          "value": "#ac4679",
          "original": {
            "value": "#ac4679"
          },
          "name": "colorSecondaryBubbleGumPink800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "800"
          ]
        },
        "900": {
          "value": "#8d3e68",
          "original": {
            "value": "#8d3e68"
          },
          "name": "colorSecondaryBubbleGumPink900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "bubbleGumPink",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "bubbleGumPink",
            "900"
          ]
        }
      },
      "grapeApePurple": {
        "50": {
          "value": "#f5eafc",
          "original": {
            "value": "#f5eafc"
          },
          "name": "colorSecondaryGrapeApePurple50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "50"
          ]
        },
        "100": {
          "value": "#efe0fa",
          "original": {
            "value": "#efe0fa"
          },
          "name": "colorSecondaryGrapeApePurple100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "100"
          ]
        },
        "200": {
          "value": "#e4cbf8",
          "original": {
            "value": "#e4cbf8"
          },
          "name": "colorSecondaryGrapeApePurple200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "200"
          ]
        },
        "300": {
          "value": "#ca94f2",
          "original": {
            "value": "#ca94f2"
          },
          "name": "colorSecondaryGrapeApePurple300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "300"
          ]
        },
        "400": {
          "value": "#a240e9",
          "original": {
            "value": "#a240e9"
          },
          "name": "colorSecondaryGrapeApePurple400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "400"
          ]
        },
        "500": {
          "value": "#8514dd",
          "original": {
            "value": "#8514dd"
          },
          "name": "colorSecondaryGrapeApePurple500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "500"
          ]
        },
        "600": {
          "value": "#8019cc",
          "original": {
            "value": "#8019cc"
          },
          "name": "colorSecondaryGrapeApePurple600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "600"
          ]
        },
        "700": {
          "value": "#7418b9",
          "original": {
            "value": "#7418b9"
          },
          "name": "colorSecondaryGrapeApePurple700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "700"
          ]
        },
        "800": {
          "value": "#6819a8",
          "original": {
            "value": "#6819a8"
          },
          "name": "colorSecondaryGrapeApePurple800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "800"
          ]
        },
        "900": {
          "value": "#57198e",
          "original": {
            "value": "#57198e"
          },
          "name": "colorSecondaryGrapeApePurple900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "grapeApePurple",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "grapeApePurple",
            "900"
          ]
        }
      },
      "cookieMonsterBlue": {
        "50": {
          "value": "#eaf6fd",
          "original": {
            "value": "#eaf6fd"
          },
          "name": "colorSecondaryCookieMonsterBlue50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "50"
          ]
        },
        "100": {
          "value": "#dff1fc",
          "original": {
            "value": "#dff1fc"
          },
          "name": "colorSecondaryCookieMonsterBlue100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "100"
          ]
        },
        "200": {
          "value": "#c9e7fb",
          "original": {
            "value": "#c9e7fb"
          },
          "name": "colorSecondaryCookieMonsterBlue200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "200"
          ]
        },
        "300": {
          "value": "#91cff6",
          "original": {
            "value": "#91cff6"
          },
          "name": "colorSecondaryCookieMonsterBlue300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "300"
          ]
        },
        "400": {
          "value": "#36adf0",
          "original": {
            "value": "#36adf0"
          },
          "name": "colorSecondaryCookieMonsterBlue400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "400"
          ]
        },
        "500": {
          "value": "#1192e8",
          "original": {
            "value": "#1192e8"
          },
          "name": "colorSecondaryCookieMonsterBlue500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "500"
          ]
        },
        "600": {
          "value": "#008cd4",
          "original": {
            "value": "#008cd4"
          },
          "name": "colorSecondaryCookieMonsterBlue600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "600"
          ]
        },
        "700": {
          "value": "#0281c0",
          "original": {
            "value": "#0281c0"
          },
          "name": "colorSecondaryCookieMonsterBlue700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "700"
          ]
        },
        "800": {
          "value": "#0774b0",
          "original": {
            "value": "#0774b0"
          },
          "name": "colorSecondaryCookieMonsterBlue800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "800"
          ]
        },
        "900": {
          "value": "#0c6293",
          "original": {
            "value": "#0c6293"
          },
          "name": "colorSecondaryCookieMonsterBlue900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "cookieMonsterBlue",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "cookieMonsterBlue",
            "900"
          ]
        }
      },
      "seaMistTeal": {
        "50": {
          "value": "#e9f9f9",
          "original": {
            "value": "#e9f9f9"
          },
          "name": "colorSecondarySeaMistTeal50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "50"
          ]
        },
        "100": {
          "value": "#ddf7f7",
          "original": {
            "value": "#ddf7f7"
          },
          "name": "colorSecondarySeaMistTeal100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "100"
          ]
        },
        "200": {
          "value": "#c6f1f2",
          "original": {
            "value": "#c6f1f2"
          },
          "name": "colorSecondarySeaMistTeal200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "200"
          ]
        },
        "300": {
          "value": "#89e3e5",
          "original": {
            "value": "#89e3e5"
          },
          "name": "colorSecondarySeaMistTeal300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "300"
          ]
        },
        "400": {
          "value": "#20ced2",
          "original": {
            "value": "#20ced2"
          },
          "name": "colorSecondarySeaMistTeal400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "400"
          ]
        },
        "500": {
          "value": "#00bdc1",
          "original": {
            "value": "#00bdc1"
          },
          "name": "colorSecondarySeaMistTeal500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "500"
          ]
        },
        "600": {
          "value": "#00aeb4",
          "original": {
            "value": "#00aeb4"
          },
          "name": "colorSecondarySeaMistTeal600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "600"
          ]
        },
        "700": {
          "value": "#009fa4",
          "original": {
            "value": "#009fa4"
          },
          "name": "colorSecondarySeaMistTeal700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "700"
          ]
        },
        "800": {
          "value": "#009096",
          "original": {
            "value": "#009096"
          },
          "name": "colorSecondarySeaMistTeal800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "800"
          ]
        },
        "900": {
          "value": "#00787f",
          "original": {
            "value": "#00787f"
          },
          "name": "colorSecondarySeaMistTeal900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "seaMistTeal",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "seaMistTeal",
            "900"
          ]
        }
      },
      "kermitGreen": {
        "50": {
          "value": "#ebf7ee",
          "original": {
            "value": "#ebf7ee"
          },
          "name": "colorSecondaryKermitGreen50",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "50"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "50"
          ]
        },
        "100": {
          "value": "#e1f4e6",
          "original": {
            "value": "#e1f4e6"
          },
          "name": "colorSecondaryKermitGreen100",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "100"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "100"
          ]
        },
        "200": {
          "value": "#ccedd5",
          "original": {
            "value": "#ccedd5"
          },
          "name": "colorSecondaryKermitGreen200",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "200"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "200"
          ]
        },
        "300": {
          "value": "#97dbac",
          "original": {
            "value": "#97dbac"
          },
          "name": "colorSecondaryKermitGreen300",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "300"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "300"
          ]
        },
        "400": {
          "value": "#44c16b",
          "original": {
            "value": "#44c16b"
          },
          "name": "colorSecondaryKermitGreen400",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "400"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "400"
          ]
        },
        "500": {
          "value": "#1ead47",
          "original": {
            "value": "#1ead47"
          },
          "name": "colorSecondaryKermitGreen500",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "500"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "500"
          ]
        },
        "600": {
          "value": "#16a248",
          "original": {
            "value": "#16a248"
          },
          "name": "colorSecondaryKermitGreen600",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "600"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "600"
          ]
        },
        "700": {
          "value": "#169344",
          "original": {
            "value": "#169344"
          },
          "name": "colorSecondaryKermitGreen700",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "700"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "700"
          ]
        },
        "800": {
          "value": "#168541",
          "original": {
            "value": "#168541"
          },
          "name": "colorSecondaryKermitGreen800",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "800"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "800"
          ]
        },
        "900": {
          "value": "#176f3c",
          "original": {
            "value": "#176f3c"
          },
          "name": "colorSecondaryKermitGreen900",
          "attributes": {
            "category": "color",
            "type": "secondary",
            "item": "kermitGreen",
            "subitem": "900"
          },
          "path": [
            "color",
            "secondary",
            "kermitGreen",
            "900"
          ]
        }
      }
    },
    "state": {
      "dark": {
        "disabled": {
          "value": "rgba(255, 255, 255, 0.15)",
          "original": {
            "value": "rgba(255, 255, 255, 0.15)"
          },
          "name": "colorStateDarkDisabled",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "dark",
            "subitem": "disabled"
          },
          "path": [
            "color",
            "state",
            "dark",
            "disabled"
          ]
        },
        "hover": {
          "value": "rgba(21, 27, 37, 0.2)",
          "original": {
            "value": "rgba(21, 27, 37, 0.2)"
          },
          "name": "colorStateDarkHover",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "dark",
            "subitem": "hover"
          },
          "path": [
            "color",
            "state",
            "dark",
            "hover"
          ]
        },
        "focus": {
          "value": "rgba(21, 27, 37, 0.2)",
          "original": {
            "value": "{color.state.dark.hover.value}"
          },
          "name": "colorStateDarkFocus",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "dark",
            "subitem": "focus"
          },
          "path": [
            "color",
            "state",
            "dark",
            "focus"
          ]
        },
        "pressed": {
          "value": "rgba(21, 27, 37, 0.25)",
          "original": {
            "value": "rgba(21, 27, 37, 0.25)"
          },
          "name": "colorStateDarkPressed",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "dark",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "state",
            "dark",
            "pressed"
          ]
        },
        "selected": {
          "value": "rgba(21, 27, 37, 0.2)",
          "original": {
            "value": "{color.state.dark.focus.value}"
          },
          "name": "colorStateDarkSelected",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "dark",
            "subitem": "selected"
          },
          "path": [
            "color",
            "state",
            "dark",
            "selected"
          ]
        }
      },
      "default": {
        "disabled": {
          "value": "rgba(21, 27, 37, 0.05)",
          "original": {
            "value": "rgba(21, 27, 37, 0.05)"
          },
          "name": "colorStateDefaultDisabled",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "default",
            "subitem": "disabled"
          },
          "path": [
            "color",
            "state",
            "default",
            "disabled"
          ]
        },
        "hover": {
          "value": "rgba(21, 27, 37, 0.15)",
          "original": {
            "value": "rgba(21, 27, 37, 0.15)"
          },
          "name": "colorStateDefaultHover",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "default",
            "subitem": "hover"
          },
          "path": [
            "color",
            "state",
            "default",
            "hover"
          ]
        },
        "focus": {
          "value": "rgba(21, 27, 37, 0.15)",
          "original": {
            "value": "{color.state.default.hover.value}"
          },
          "name": "colorStateDefaultFocus",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "default",
            "subitem": "focus"
          },
          "path": [
            "color",
            "state",
            "default",
            "focus"
          ]
        },
        "pressed": {
          "value": "rgba(21, 27, 37, 0.2)",
          "original": {
            "value": "rgba(21, 27, 37, 0.2)"
          },
          "name": "colorStateDefaultPressed",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "default",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "state",
            "default",
            "pressed"
          ]
        },
        "selected": {
          "value": "rgba(0, 108, 250, 0.15)",
          "original": {
            "value": "rgba(0, 108, 250, 0.15)"
          },
          "name": "colorStateDefaultSelected",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "default",
            "subitem": "selected"
          },
          "path": [
            "color",
            "state",
            "default",
            "selected"
          ]
        }
      },
      "light": {
        "disabled": {
          "value": "rgba(255, 255, 255, 0.1)",
          "original": {
            "value": "rgba(255, 255, 255, 0.1)"
          },
          "name": "colorStateLightDisabled",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "light",
            "subitem": "disabled"
          },
          "path": [
            "color",
            "state",
            "light",
            "disabled"
          ]
        },
        "hover": {
          "value": "rgba(255, 255, 255, 0.2)",
          "original": {
            "value": "rgba(255, 255, 255, 0.2)"
          },
          "name": "colorStateLightHover",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "light",
            "subitem": "hover"
          },
          "path": [
            "color",
            "state",
            "light",
            "hover"
          ]
        },
        "focus": {
          "value": "rgba(255, 255, 255, 0.2)",
          "original": {
            "value": "{color.state.light.hover.value}"
          },
          "name": "colorStateLightFocus",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "light",
            "subitem": "focus"
          },
          "path": [
            "color",
            "state",
            "light",
            "focus"
          ]
        },
        "pressed": {
          "value": "rgba(255, 255, 255, 0.25)",
          "original": {
            "value": "rgba(255, 255, 255, 0.25)"
          },
          "name": "colorStateLightPressed",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "light",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "state",
            "light",
            "pressed"
          ]
        },
        "selected": {
          "value": "rgba(255, 255, 255, 0.2)",
          "original": {
            "value": "{color.state.light.focus.value}"
          },
          "name": "colorStateLightSelected",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "light",
            "subitem": "selected"
          },
          "path": [
            "color",
            "state",
            "light",
            "selected"
          ]
        }
      },
      "primary": {
        "disabled": {
          "value": "rgba(21, 27, 37, 0.05)",
          "original": {
            "value": "rgba(21, 27, 37, 0.05)"
          },
          "name": "colorStatePrimaryDisabled",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "primary",
            "subitem": "disabled"
          },
          "path": [
            "color",
            "state",
            "primary",
            "disabled"
          ]
        },
        "hover": {
          "value": "rgba(0, 108, 250, 0.15)",
          "original": {
            "value": "rgba(0, 108, 250, 0.15)"
          },
          "name": "colorStatePrimaryHover",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "primary",
            "subitem": "hover"
          },
          "path": [
            "color",
            "state",
            "primary",
            "hover"
          ]
        },
        "focus": {
          "value": "rgba(0, 108, 250, 0.15)",
          "original": {
            "value": "{color.state.primary.hover.value}"
          },
          "name": "colorStatePrimaryFocus",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "primary",
            "subitem": "focus"
          },
          "path": [
            "color",
            "state",
            "primary",
            "focus"
          ]
        },
        "pressed": {
          "value": "rgba(0, 108, 250, 0.25)",
          "original": {
            "value": "rgba(0, 108, 250, 0.25)"
          },
          "name": "colorStatePrimaryPressed",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "primary",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "state",
            "primary",
            "pressed"
          ]
        },
        "selected": {
          "value": "rgba(0, 108, 250, 0.15)",
          "original": {
            "value": "rgba(0, 108, 250, 0.15)"
          },
          "name": "colorStatePrimarySelected",
          "attributes": {
            "category": "color",
            "type": "state",
            "item": "primary",
            "subitem": "selected"
          },
          "path": [
            "color",
            "state",
            "primary",
            "selected"
          ]
        }
      }
    },
    "system": {
      "default": {
        "50": {
          "value": "#f3f3f4",
          "original": {
            "value": "#F3F3F4"
          },
          "name": "colorSystemDefault50",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "50"
          },
          "path": [
            "color",
            "system",
            "default",
            "50"
          ]
        },
        "100": {
          "value": "#edeeee",
          "original": {
            "value": "#EDEEEE"
          },
          "name": "colorSystemDefault100",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "100"
          },
          "path": [
            "color",
            "system",
            "default",
            "100"
          ]
        },
        "200": {
          "value": "#e1e2e3",
          "original": {
            "value": "#E1E2E3"
          },
          "name": "colorSystemDefault200",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "200"
          },
          "path": [
            "color",
            "system",
            "default",
            "200"
          ]
        },
        "300": {
          "value": "#c4c6c8",
          "original": {
            "value": "#C4C6C8"
          },
          "name": "colorSystemDefault300",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "300"
          },
          "path": [
            "color",
            "system",
            "default",
            "300"
          ]
        },
        "400": {
          "value": "#9b9da2",
          "original": {
            "value": "#9B9DA2"
          },
          "name": "colorSystemDefault400",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "400"
          },
          "path": [
            "color",
            "system",
            "default",
            "400"
          ]
        },
        "500": {
          "value": "#8a8d92",
          "original": {
            "value": "#8A8D92"
          },
          "name": "colorSystemDefault500",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "500"
          },
          "path": [
            "color",
            "system",
            "default",
            "500"
          ]
        },
        "600": {
          "value": "#787b82",
          "original": {
            "value": "#787B82"
          },
          "name": "colorSystemDefault600",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "600"
          },
          "path": [
            "color",
            "system",
            "default",
            "600"
          ]
        },
        "700": {
          "value": "#6c7076",
          "original": {
            "value": "#6C7076"
          },
          "name": "colorSystemDefault700",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "700"
          },
          "path": [
            "color",
            "system",
            "default",
            "700"
          ]
        },
        "800": {
          "value": "#60646c",
          "original": {
            "value": "#60646C"
          },
          "name": "colorSystemDefault800",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "800"
          },
          "path": [
            "color",
            "system",
            "default",
            "800"
          ]
        },
        "900": {
          "value": "#4f545b",
          "original": {
            "value": "#4F545B"
          },
          "name": "colorSystemDefault900",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "default",
            "subitem": "900"
          },
          "path": [
            "color",
            "system",
            "default",
            "900"
          ]
        }
      },
      "neutral": {
        "50": {
          "value": "#e6edf7",
          "original": {
            "value": "#E6EDF7"
          },
          "name": "colorSystemNeutral50",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "50"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "50"
          ]
        },
        "100": {
          "value": "#dae4f3",
          "original": {
            "value": "#DAE4F3"
          },
          "name": "colorSystemNeutral100",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "100"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "100"
          ]
        },
        "200": {
          "value": "#c0d2ea",
          "original": {
            "value": "#C0D2EA"
          },
          "name": "colorSystemNeutral200",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "200"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "200"
          ]
        },
        "300": {
          "value": "#83a7d7",
          "original": {
            "value": "#83A7D7"
          },
          "name": "colorSystemNeutral300",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "300"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "300"
          ]
        },
        "400": {
          "value": "#2b69ba",
          "original": {
            "value": "#2B69BA"
          },
          "name": "colorSystemNeutral400",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "400"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "400"
          ]
        },
        "500": {
          "value": "#074faf",
          "original": {
            "value": "#074FAF"
          },
          "name": "colorSystemNeutral500",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "500"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "500"
          ]
        },
        "600": {
          "value": "#08479a",
          "original": {
            "value": "#08479A"
          },
          "name": "colorSystemNeutral600",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "600"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "600"
          ]
        },
        "700": {
          "value": "#0a428c",
          "original": {
            "value": "#0A428C"
          },
          "name": "colorSystemNeutral700",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "700"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "700"
          ]
        },
        "800": {
          "value": "#0b3c7e",
          "original": {
            "value": "#0B3C7E"
          },
          "name": "colorSystemNeutral800",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "800"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "800"
          ]
        },
        "900": {
          "value": "#0e356a",
          "original": {
            "value": "#0E356A"
          },
          "name": "colorSystemNeutral900",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "neutral",
            "subitem": "900"
          },
          "path": [
            "color",
            "system",
            "neutral",
            "900"
          ]
        }
      },
      "positive": {
        "50": {
          "value": "#e6f6e6",
          "original": {
            "value": "#E6F6E6"
          },
          "name": "colorSystemPositive50",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "50"
          },
          "path": [
            "color",
            "system",
            "positive",
            "50"
          ]
        },
        "100": {
          "value": "#daf2d9",
          "original": {
            "value": "#DAF2D9"
          },
          "name": "colorSystemPositive100",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "100"
          },
          "path": [
            "color",
            "system",
            "positive",
            "100"
          ]
        },
        "200": {
          "value": "#c1eabf",
          "original": {
            "value": "#C1EABF"
          },
          "name": "colorSystemPositive200",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "200"
          },
          "path": [
            "color",
            "system",
            "positive",
            "200"
          ]
        },
        "300": {
          "value": "#84d680",
          "original": {
            "value": "#84D680"
          },
          "name": "colorSystemPositive300",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "300"
          },
          "path": [
            "color",
            "system",
            "positive",
            "300"
          ]
        },
        "400": {
          "value": "#2db926",
          "original": {
            "value": "#2DB926"
          },
          "name": "colorSystemPositive400",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "400"
          },
          "path": [
            "color",
            "system",
            "positive",
            "400"
          ]
        },
        "500": {
          "value": "#09ad00",
          "original": {
            "value": "#09AD00"
          },
          "name": "colorSystemPositive500",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "500"
          },
          "path": [
            "color",
            "system",
            "positive",
            "500"
          ]
        },
        "600": {
          "value": "#0a9706",
          "original": {
            "value": "#0A9706"
          },
          "name": "colorSystemPositive600",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "600"
          },
          "path": [
            "color",
            "system",
            "positive",
            "600"
          ]
        },
        "700": {
          "value": "#0b8809",
          "original": {
            "value": "#0B8809"
          },
          "name": "colorSystemPositive700",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "700"
          },
          "path": [
            "color",
            "system",
            "positive",
            "700"
          ]
        },
        "800": {
          "value": "#0c790d",
          "original": {
            "value": "#0C790D"
          },
          "name": "colorSystemPositive800",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "800"
          },
          "path": [
            "color",
            "system",
            "positive",
            "800"
          ]
        },
        "900": {
          "value": "#0f6413",
          "original": {
            "value": "#0F6413"
          },
          "name": "colorSystemPositive900",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "positive",
            "subitem": "900"
          },
          "path": [
            "color",
            "system",
            "positive",
            "900"
          ]
        }
      },
      "warning": {
        "50": {
          "value": "#fef5e8",
          "original": {
            "value": "#FEF5E8"
          },
          "name": "colorSystemWarning50",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "50"
          },
          "path": [
            "color",
            "system",
            "warning",
            "50"
          ]
        },
        "100": {
          "value": "#fef0dc",
          "original": {
            "value": "#FEF0DC"
          },
          "name": "colorSystemWarning100",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "100"
          },
          "path": [
            "color",
            "system",
            "warning",
            "100"
          ]
        },
        "200": {
          "value": "#fde6c5",
          "original": {
            "value": "#FDE6C5"
          },
          "name": "colorSystemWarning200",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "200"
          },
          "path": [
            "color",
            "system",
            "warning",
            "200"
          ]
        },
        "300": {
          "value": "#fccd8c",
          "original": {
            "value": "#FCCD8C"
          },
          "name": "colorSystemWarning300",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "300"
          },
          "path": [
            "color",
            "system",
            "warning",
            "300"
          ]
        },
        "400": {
          "value": "#faaa3b",
          "original": {
            "value": "#FAAA3B"
          },
          "name": "colorSystemWarning400",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "400"
          },
          "path": [
            "color",
            "system",
            "warning",
            "400"
          ]
        },
        "500": {
          "value": "#fa9c19",
          "original": {
            "value": "#FA9C19"
          },
          "name": "colorSystemWarning500",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "500"
          },
          "path": [
            "color",
            "system",
            "warning",
            "500"
          ]
        },
        "600": {
          "value": "#d7881b",
          "original": {
            "value": "#D7881B"
          },
          "name": "colorSystemWarning600",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "600"
          },
          "path": [
            "color",
            "system",
            "warning",
            "600"
          ]
        },
        "700": {
          "value": "#c07b1b",
          "original": {
            "value": "#C07B1B"
          },
          "name": "colorSystemWarning700",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "700"
          },
          "path": [
            "color",
            "system",
            "warning",
            "700"
          ]
        },
        "800": {
          "value": "#a96e1d",
          "original": {
            "value": "#A96E1D"
          },
          "name": "colorSystemWarning800",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "800"
          },
          "path": [
            "color",
            "system",
            "warning",
            "800"
          ]
        },
        "900": {
          "value": "#875b1f",
          "original": {
            "value": "#875B1F"
          },
          "name": "colorSystemWarning900",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "warning",
            "subitem": "900"
          },
          "path": [
            "color",
            "system",
            "warning",
            "900"
          ]
        }
      },
      "negative": {
        "50": {
          "value": "#fde6e6",
          "original": {
            "value": "#FDE6E6"
          },
          "name": "colorSystemNegative50",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "50"
          },
          "path": [
            "color",
            "system",
            "negative",
            "50"
          ]
        },
        "100": {
          "value": "#fcd9d9",
          "original": {
            "value": "#FCD9D9"
          },
          "name": "colorSystemNegative100",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "100"
          },
          "path": [
            "color",
            "system",
            "negative",
            "100"
          ]
        },
        "200": {
          "value": "#f9bfbf",
          "original": {
            "value": "#F9BFBF"
          },
          "name": "colorSystemNegative200",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "200"
          },
          "path": [
            "color",
            "system",
            "negative",
            "200"
          ]
        },
        "300": {
          "value": "#f58080",
          "original": {
            "value": "#F58080"
          },
          "name": "colorSystemNegative300",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "300"
          },
          "path": [
            "color",
            "system",
            "negative",
            "300"
          ]
        },
        "400": {
          "value": "#ed2626",
          "original": {
            "value": "#ED2626"
          },
          "name": "colorSystemNegative400",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "400"
          },
          "path": [
            "color",
            "system",
            "negative",
            "400"
          ]
        },
        "500": {
          "value": "#eb0000",
          "original": {
            "value": "#EB0000"
          },
          "name": "colorSystemNegative500",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "500"
          },
          "path": [
            "color",
            "system",
            "negative",
            "500"
          ]
        },
        "600": {
          "value": "#ca0406",
          "original": {
            "value": "#CA0406"
          },
          "name": "colorSystemNegative600",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "600"
          },
          "path": [
            "color",
            "system",
            "negative",
            "600"
          ]
        },
        "700": {
          "value": "#b50709",
          "original": {
            "value": "#B50709"
          },
          "name": "colorSystemNegative700",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "700"
          },
          "path": [
            "color",
            "system",
            "negative",
            "700"
          ]
        },
        "800": {
          "value": "#9f090d",
          "original": {
            "value": "#9F090D"
          },
          "name": "colorSystemNegative800",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "800"
          },
          "path": [
            "color",
            "system",
            "negative",
            "800"
          ]
        },
        "900": {
          "value": "#800e13",
          "original": {
            "value": "#800E13"
          },
          "name": "colorSystemNegative900",
          "attributes": {
            "category": "color",
            "type": "system",
            "item": "negative",
            "subitem": "900"
          },
          "path": [
            "color",
            "system",
            "negative",
            "900"
          ]
        }
      }
    },
    "text": {
      "dark": {
        "disabled": {
          "value": "rgba(255, 255, 255, 0.4)",
          "original": {
            "value": "rgba(255, 255, 255, 0.4)"
          },
          "name": "colorTextDarkDisabled",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "dark",
            "subitem": "disabled"
          },
          "path": [
            "color",
            "text",
            "dark",
            "disabled"
          ]
        },
        "primary": {
          "value": "#ffffff",
          "original": {
            "value": "{color.additional.light.value}"
          },
          "name": "colorTextDarkPrimary",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "dark",
            "subitem": "primary"
          },
          "path": [
            "color",
            "text",
            "dark",
            "primary"
          ]
        },
        "secondary": {
          "value": "rgba(255, 255, 255, 0.7)",
          "original": {
            "value": "rgba(255, 255, 255, 0.7)"
          },
          "name": "colorTextDarkSecondary",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "dark",
            "subitem": "secondary"
          },
          "path": [
            "color",
            "text",
            "dark",
            "secondary"
          ]
        }
      },
      "light": {
        "disabled": {
          "value": "rgba(21, 27, 37, 0.4)",
          "original": {
            "value": "rgba(21, 27, 37, 0.4)"
          },
          "name": "colorTextLightDisabled",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "light",
            "subitem": "disabled"
          },
          "path": [
            "color",
            "text",
            "light",
            "disabled"
          ]
        },
        "link": {
          "value": "#006cfa",
          "original": {
            "value": "{color.primary.500.value}"
          },
          "name": "colorTextLightLink",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "light",
            "subitem": "link"
          },
          "path": [
            "color",
            "text",
            "light",
            "link"
          ]
        },
        "linkHover": {
          "value": "#035fda",
          "original": {
            "value": "{color.primary.600.value}"
          },
          "name": "colorTextLightLinkHover",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "light",
            "subitem": "linkHover"
          },
          "path": [
            "color",
            "text",
            "light",
            "linkHover"
          ]
        },
        "primary": {
          "value": "#151b25",
          "original": {
            "value": "{color.additional.dark.value}"
          },
          "name": "colorTextLightPrimary",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "light",
            "subitem": "primary"
          },
          "path": [
            "color",
            "text",
            "light",
            "primary"
          ]
        },
        "secondary": {
          "value": "rgba(21, 27, 37, 0.7)",
          "original": {
            "value": "rgba(21, 27, 37, 0.7)"
          },
          "name": "colorTextLightSecondary",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "light",
            "subitem": "secondary"
          },
          "path": [
            "color",
            "text",
            "light",
            "secondary"
          ]
        }
      }
    }
  },
  "elevation": {
    "0": {
      "value": "none",
      "original": {
        "value": "none"
      },
      "name": "elevation0",
      "attributes": {
        "category": "elevation",
        "type": "0"
      },
      "path": [
        "elevation",
        "0"
      ]
    },
    "1": {
      "value": "0 1px 3px 0 rgba(21,27,37,0.50)",
      "original": {
        "value": "0 1px 3px 0 rgba(21,27,37,0.50)"
      },
      "name": "elevation1",
      "attributes": {
        "category": "elevation",
        "type": "1"
      },
      "path": [
        "elevation",
        "1"
      ]
    },
    "2": {
      "value": "0 2px 5px 0 rgba(21,27,37,0.50)",
      "original": {
        "value": "0 2px 5px 0 rgba(21,27,37,0.50)"
      },
      "name": "elevation2",
      "attributes": {
        "category": "elevation",
        "type": "2"
      },
      "path": [
        "elevation",
        "2"
      ]
    },
    "3": {
      "value": "0 2px 7px 1px rgba(21,27,37,0.45)",
      "original": {
        "value": "0 2px 7px 1px rgba(21,27,37,0.45)"
      },
      "name": "elevation3",
      "attributes": {
        "category": "elevation",
        "type": "3"
      },
      "path": [
        "elevation",
        "3"
      ]
    },
    "4": {
      "value": "0 3px 12px 0 rgba(21,27,37,0.42)",
      "original": {
        "value": "0 3px 12px 0 rgba(21,27,37,0.42)"
      },
      "name": "elevation4",
      "attributes": {
        "category": "elevation",
        "type": "4"
      },
      "path": [
        "elevation",
        "4"
      ]
    },
    "5": {
      "value": "0 8px 25px 2px rgba(21,27,37,0.35)",
      "original": {
        "value": "0 8px 25px 2px rgba(21,27,37,0.35)"
      },
      "name": "elevation5",
      "attributes": {
        "category": "elevation",
        "type": "5"
      },
      "path": [
        "elevation",
        "5"
      ]
    },
    "6": {
      "value": "0 12px 40px 2px rgba(21,27,37,0.30)",
      "original": {
        "value": "0 12px 40px 2px rgba(21,27,37,0.30)"
      },
      "name": "elevation6",
      "attributes": {
        "category": "elevation",
        "type": "6"
      },
      "path": [
        "elevation",
        "6"
      ]
    }
  },
  "font": {
    "family": {
      "obscured": {
        "value": "Obscured Input, sans-serif",
        "original": {
          "value": "Obscured Input, sans-serif"
        },
        "name": "fontFamilyObscured",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "obscured"
        },
        "path": [
          "font",
          "family",
          "obscured"
        ]
      },
      "main": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "Roboto, sans-serif"
        },
        "name": "fontFamilyMain",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "main"
        },
        "path": [
          "font",
          "family",
          "main"
        ]
      },
      "alternate": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "Roboto, sans-serif"
        },
        "name": "fontFamilyAlternate",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "alternate"
        },
        "path": [
          "font",
          "family",
          "alternate"
        ]
      }
    },
    "size": {
      "default": {
        "value": "1rem",
        "original": {
          "value": "16px"
        },
        "name": "fontSizeDefault",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "default"
        },
        "path": [
          "font",
          "size",
          "default"
        ]
      },
      "display1": {
        "value": "2.625rem",
        "original": {
          "value": "42px"
        },
        "name": "fontSizeDisplay1",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "display1"
        },
        "path": [
          "font",
          "size",
          "display1"
        ]
      },
      "display2": {
        "value": "2.125rem",
        "original": {
          "value": "34px"
        },
        "name": "fontSizeDisplay2",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "display2"
        },
        "path": [
          "font",
          "size",
          "display2"
        ]
      },
      "display3": {
        "value": "1.875rem",
        "original": {
          "value": "30px"
        },
        "name": "fontSizeDisplay3",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "display3"
        },
        "path": [
          "font",
          "size",
          "display3"
        ]
      },
      "heading1": {
        "value": "1.5rem",
        "original": {
          "value": "24px"
        },
        "name": "fontSizeHeading1",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "heading1"
        },
        "path": [
          "font",
          "size",
          "heading1"
        ]
      },
      "heading2": {
        "value": "1.25rem",
        "original": {
          "value": "20px"
        },
        "name": "fontSizeHeading2",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "heading2"
        },
        "path": [
          "font",
          "size",
          "heading2"
        ]
      },
      "subtitle1": {
        "value": "1.125rem",
        "original": {
          "value": "18px"
        },
        "name": "fontSizeSubtitle1",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "subtitle1"
        },
        "path": [
          "font",
          "size",
          "subtitle1"
        ]
      },
      "subtitle2": {
        "value": "1rem",
        "original": {
          "value": "{font.size.default.value}"
        },
        "name": "fontSizeSubtitle2",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "subtitle2"
        },
        "path": [
          "font",
          "size",
          "subtitle2"
        ]
      },
      "body1": {
        "value": "0.875rem",
        "original": {
          "value": "14px"
        },
        "name": "fontSizeBody1",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "body1"
        },
        "path": [
          "font",
          "size",
          "body1"
        ]
      },
      "body2": {
        "value": "1rem",
        "original": {
          "value": "{font.size.default.value}"
        },
        "name": "fontSizeBody2",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "body2"
        },
        "path": [
          "font",
          "size",
          "body2"
        ]
      },
      "caption": {
        "value": "0.75rem",
        "original": {
          "value": "12px"
        },
        "name": "fontSizeCaption",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "caption"
        },
        "path": [
          "font",
          "size",
          "caption"
        ]
      },
      "button": {
        "value": "0.875rem",
        "original": {
          "value": "{font.size.body1.value}"
        },
        "name": "fontSizeButton",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "button"
        },
        "path": [
          "font",
          "size",
          "button"
        ]
      },
      "overline": {
        "value": "0.625rem",
        "original": {
          "value": "10px"
        },
        "name": "fontSizeOverline",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "overline"
        },
        "path": [
          "font",
          "size",
          "overline"
        ]
      },
      "link": {
        "value": "0.875rem",
        "original": {
          "value": "{font.size.body1.value}"
        },
        "name": "fontSizeLink",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "link"
        },
        "path": [
          "font",
          "size",
          "link"
        ]
      }
    },
    "weight": {
      "light": {
        "value": "300",
        "original": {
          "value": "300"
        },
        "name": "fontWeightLight",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "light"
        },
        "path": [
          "font",
          "weight",
          "light"
        ]
      },
      "regular": {
        "value": "400",
        "original": {
          "value": "400"
        },
        "name": "fontWeightRegular",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "regular"
        },
        "path": [
          "font",
          "weight",
          "regular"
        ]
      },
      "medium": {
        "value": "500",
        "original": {
          "value": "500"
        },
        "name": "fontWeightMedium",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "medium"
        },
        "path": [
          "font",
          "weight",
          "medium"
        ]
      },
      "semiBold": {
        "value": "600",
        "original": {
          "value": "600"
        },
        "name": "fontWeightSemiBold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "semiBold"
        },
        "path": [
          "font",
          "weight",
          "semiBold"
        ]
      },
      "bold": {
        "value": "700",
        "original": {
          "value": "700"
        },
        "name": "fontWeightBold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "bold"
        },
        "path": [
          "font",
          "weight",
          "bold"
        ]
      }
    }
  },
  "typography": {
    "display1": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyDisplay1FontFamily",
        "attributes": {
          "category": "typography",
          "type": "display1",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "display1",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "2.625rem",
        "original": {
          "value": "{font.size.display1.value}"
        },
        "name": "typographyDisplay1FontSize",
        "attributes": {
          "category": "typography",
          "type": "display1",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "display1",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "300",
        "original": {
          "value": "{font.weight.light.value}"
        },
        "name": "typographyDisplay1FontWeight",
        "attributes": {
          "category": "typography",
          "type": "display1",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "display1",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": 0,
        "original": {
          "value": 0
        },
        "name": "typographyDisplay1LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "display1",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "display1",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "56px",
        "original": {
          "value": "56px"
        },
        "name": "typographyDisplay1LineHeight",
        "attributes": {
          "category": "typography",
          "type": "display1",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "display1",
          "lineHeight"
        ]
      }
    },
    "display2": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyDisplay2FontFamily",
        "attributes": {
          "category": "typography",
          "type": "display2",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "display2",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "2.125rem",
        "original": {
          "value": "{font.size.display2.value}"
        },
        "name": "typographyDisplay2FontSize",
        "attributes": {
          "category": "typography",
          "type": "display2",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "display2",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyDisplay2FontWeight",
        "attributes": {
          "category": "typography",
          "type": "display2",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "display2",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.5px",
        "original": {
          "value": "0.5px"
        },
        "name": "typographyDisplay2LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "display2",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "display2",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "40px",
        "original": {
          "value": "40px"
        },
        "name": "typographyDisplay2LineHeight",
        "attributes": {
          "category": "typography",
          "type": "display2",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "display2",
          "lineHeight"
        ]
      }
    },
    "display3": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyDisplay3FontFamily",
        "attributes": {
          "category": "typography",
          "type": "display3",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "display3",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1.875rem",
        "original": {
          "value": "{font.size.display3.value}"
        },
        "name": "typographyDisplay3FontSize",
        "attributes": {
          "category": "typography",
          "type": "display3",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "display3",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyDisplay3FontWeight",
        "attributes": {
          "category": "typography",
          "type": "display3",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "display3",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.25px",
        "original": {
          "value": "0.25px"
        },
        "name": "typographyDisplay3LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "display3",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "display3",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "40px",
        "original": {
          "value": "40px"
        },
        "name": "typographyDisplay3LineHeight",
        "attributes": {
          "category": "typography",
          "type": "display3",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "display3",
          "lineHeight"
        ]
      }
    },
    "heading1": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyHeading1FontFamily",
        "attributes": {
          "category": "typography",
          "type": "heading1",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "heading1",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1.5rem",
        "original": {
          "value": "{font.size.heading1.value}"
        },
        "name": "typographyHeading1FontSize",
        "attributes": {
          "category": "typography",
          "type": "heading1",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "heading1",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyHeading1FontWeight",
        "attributes": {
          "category": "typography",
          "type": "heading1",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "heading1",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0px",
        "original": {
          "value": "0px"
        },
        "name": "typographyHeading1LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "heading1",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "heading1",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "32px",
        "original": {
          "value": "32px"
        },
        "name": "typographyHeading1LineHeight",
        "attributes": {
          "category": "typography",
          "type": "heading1",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "heading1",
          "lineHeight"
        ]
      }
    },
    "heading2": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyHeading2FontFamily",
        "attributes": {
          "category": "typography",
          "type": "heading2",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "heading2",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1.25rem",
        "original": {
          "value": "{font.size.heading2.value}"
        },
        "name": "typographyHeading2FontSize",
        "attributes": {
          "category": "typography",
          "type": "heading2",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "heading2",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "600",
        "original": {
          "value": "{font.weight.semiBold.value}"
        },
        "name": "typographyHeading2FontWeight",
        "attributes": {
          "category": "typography",
          "type": "heading2",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "heading2",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.15px",
        "original": {
          "value": "0.15px"
        },
        "name": "typographyHeading2LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "heading2",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "heading2",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "24px",
        "original": {
          "value": "24px"
        },
        "name": "typographyHeading2LineHeight",
        "attributes": {
          "category": "typography",
          "type": "heading2",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "heading2",
          "lineHeight"
        ]
      }
    },
    "subtitle1": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographySubtitle1FontFamily",
        "attributes": {
          "category": "typography",
          "type": "subtitle1",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "subtitle1",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1.125rem",
        "original": {
          "value": "{font.size.subtitle1.value}"
        },
        "name": "typographySubtitle1FontSize",
        "attributes": {
          "category": "typography",
          "type": "subtitle1",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "subtitle1",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographySubtitle1FontWeight",
        "attributes": {
          "category": "typography",
          "type": "subtitle1",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "subtitle1",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.15px",
        "original": {
          "value": "0.15px"
        },
        "name": "typographySubtitle1LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "subtitle1",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "subtitle1",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "24px",
        "original": {
          "value": "24px"
        },
        "name": "typographySubtitle1LineHeight",
        "attributes": {
          "category": "typography",
          "type": "subtitle1",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "subtitle1",
          "lineHeight"
        ]
      }
    },
    "subtitle2": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographySubtitle2FontFamily",
        "attributes": {
          "category": "typography",
          "type": "subtitle2",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "subtitle2",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1rem",
        "original": {
          "value": "{font.size.subtitle2.value}"
        },
        "name": "typographySubtitle2FontSize",
        "attributes": {
          "category": "typography",
          "type": "subtitle2",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "subtitle2",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographySubtitle2FontWeight",
        "attributes": {
          "category": "typography",
          "type": "subtitle2",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "subtitle2",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.1px",
        "original": {
          "value": "0.1px"
        },
        "name": "typographySubtitle2LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "subtitle2",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "subtitle2",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "24px",
        "original": {
          "value": "24px"
        },
        "name": "typographySubtitle2LineHeight",
        "attributes": {
          "category": "typography",
          "type": "subtitle2",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "subtitle2",
          "lineHeight"
        ]
      }
    },
    "subtitle3": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographySubtitle3FontFamily",
        "attributes": {
          "category": "typography",
          "type": "subtitle3",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "subtitle3",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1rem",
        "original": {
          "value": "{font.size.subtitle2.value}"
        },
        "name": "typographySubtitle3FontSize",
        "attributes": {
          "category": "typography",
          "type": "subtitle3",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "subtitle3",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographySubtitle3FontWeight",
        "attributes": {
          "category": "typography",
          "type": "subtitle3",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "subtitle3",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.1px",
        "original": {
          "value": "0.1px"
        },
        "name": "typographySubtitle3LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "subtitle3",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "subtitle3",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "24px",
        "original": {
          "value": "24px"
        },
        "name": "typographySubtitle3LineHeight",
        "attributes": {
          "category": "typography",
          "type": "subtitle3",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "subtitle3",
          "lineHeight"
        ]
      }
    },
    "body1": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyBody1FontFamily",
        "attributes": {
          "category": "typography",
          "type": "body1",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "body1",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "0.875rem",
        "original": {
          "value": "{font.size.body1.value}"
        },
        "name": "typographyBody1FontSize",
        "attributes": {
          "category": "typography",
          "type": "body1",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "body1",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyBody1FontWeight",
        "attributes": {
          "category": "typography",
          "type": "body1",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "body1",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": 0,
        "original": {
          "value": 0
        },
        "name": "typographyBody1LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "body1",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "body1",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "20px",
        "original": {
          "value": "20px"
        },
        "name": "typographyBody1LineHeight",
        "attributes": {
          "category": "typography",
          "type": "body1",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "body1",
          "lineHeight"
        ]
      }
    },
    "body2": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyBody2FontFamily",
        "attributes": {
          "category": "typography",
          "type": "body2",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "body2",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1rem",
        "original": {
          "value": "{font.size.body2.value}"
        },
        "name": "typographyBody2FontSize",
        "attributes": {
          "category": "typography",
          "type": "body2",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "body2",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyBody2FontWeight",
        "attributes": {
          "category": "typography",
          "type": "body2",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "body2",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": 0,
        "original": {
          "value": 0
        },
        "name": "typographyBody2LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "body2",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "body2",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "24px",
        "original": {
          "value": "24px"
        },
        "name": "typographyBody2LineHeight",
        "attributes": {
          "category": "typography",
          "type": "body2",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "body2",
          "lineHeight"
        ]
      }
    },
    "caption": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyCaptionFontFamily",
        "attributes": {
          "category": "typography",
          "type": "caption",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "caption",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "0.75rem",
        "original": {
          "value": "{font.size.caption.value}"
        },
        "name": "typographyCaptionFontSize",
        "attributes": {
          "category": "typography",
          "type": "caption",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "caption",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyCaptionFontWeight",
        "attributes": {
          "category": "typography",
          "type": "caption",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "caption",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": 0,
        "original": {
          "value": 0
        },
        "name": "typographyCaptionLetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "caption",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "caption",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "14px",
        "original": {
          "value": "14px"
        },
        "name": "typographyCaptionLineHeight",
        "attributes": {
          "category": "typography",
          "type": "caption",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "caption",
          "lineHeight"
        ]
      }
    },
    "button": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyButtonFontFamily",
        "attributes": {
          "category": "typography",
          "type": "button",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "button",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "0.875rem",
        "original": {
          "value": "{font.size.button.value}"
        },
        "name": "typographyButtonFontSize",
        "attributes": {
          "category": "typography",
          "type": "button",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "button",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographyButtonFontWeight",
        "attributes": {
          "category": "typography",
          "type": "button",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "button",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.25px",
        "original": {
          "value": "0.25px"
        },
        "name": "typographyButtonLetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "button",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "button",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "20px",
        "original": {
          "value": "20px"
        },
        "name": "typographyButtonLineHeight",
        "attributes": {
          "category": "typography",
          "type": "button",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "button",
          "lineHeight"
        ]
      }
    },
    "overline": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyOverlineFontFamily",
        "attributes": {
          "category": "typography",
          "type": "overline",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "overline",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "0.625rem",
        "original": {
          "value": "{font.size.overline.value}"
        },
        "name": "typographyOverlineFontSize",
        "attributes": {
          "category": "typography",
          "type": "overline",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "overline",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographyOverlineFontWeight",
        "attributes": {
          "category": "typography",
          "type": "overline",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "overline",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.15px",
        "original": {
          "value": "0.15px"
        },
        "name": "typographyOverlineLetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "overline",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "overline",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "14px",
        "original": {
          "value": "14px"
        },
        "name": "typographyOverlineLineHeight",
        "attributes": {
          "category": "typography",
          "type": "overline",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "overline",
          "lineHeight"
        ]
      }
    },
    "link": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyLinkFontFamily",
        "attributes": {
          "category": "typography",
          "type": "link",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "link",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "0.875rem",
        "original": {
          "value": "{font.size.link.value}"
        },
        "name": "typographyLinkFontSize",
        "attributes": {
          "category": "typography",
          "type": "link",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "link",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographyLinkFontWeight",
        "attributes": {
          "category": "typography",
          "type": "link",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "link",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.25px",
        "original": {
          "value": "0.25px"
        },
        "name": "typographyLinkLetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "link",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "link",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "20px",
        "original": {
          "value": "20px"
        },
        "name": "typographyLinkLineHeight",
        "attributes": {
          "category": "typography",
          "type": "link",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "link",
          "lineHeight"
        ]
      }
    },
    "link2": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyLink2FontFamily",
        "attributes": {
          "category": "typography",
          "type": "link2",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "link2",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "0.875rem",
        "original": {
          "value": "{font.size.link.value}"
        },
        "name": "typographyLink2FontSize",
        "attributes": {
          "category": "typography",
          "type": "link2",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "link2",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographyLink2FontWeight",
        "attributes": {
          "category": "typography",
          "type": "link2",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "link2",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": "0.25px",
        "original": {
          "value": "0.25px"
        },
        "name": "typographyLink2LetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "link2",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "link2",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "20px",
        "original": {
          "value": "20px"
        },
        "name": "typographyLink2LineHeight",
        "attributes": {
          "category": "typography",
          "type": "link2",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "link2",
          "lineHeight"
        ]
      }
    },
    "table": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyTableFontFamily",
        "attributes": {
          "category": "typography",
          "type": "table",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "table",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographyTableFontWeight",
        "attributes": {
          "category": "typography",
          "type": "table",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "table",
          "fontWeight"
        ]
      }
    },
    "statusIndicator": {
      "fontWeight": {
        "value": "500",
        "original": {
          "value": "{font.weight.medium.value}"
        },
        "name": "typographyStatusIndicatorFontWeight",
        "attributes": {
          "category": "typography",
          "type": "statusIndicator",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "statusIndicator",
          "fontWeight"
        ]
      }
    },
    "pageHeaderTitle": {
      "fontFamily": {
        "value": "Roboto, sans-serif",
        "original": {
          "value": "{font.family.main.value}"
        },
        "name": "typographyPageHeaderTitleFontFamily",
        "attributes": {
          "category": "typography",
          "type": "pageHeaderTitle",
          "item": "fontFamily"
        },
        "path": [
          "typography",
          "pageHeaderTitle",
          "fontFamily"
        ]
      },
      "fontSize": {
        "value": "1.5rem",
        "original": {
          "value": "{font.size.heading1.value}"
        },
        "name": "typographyPageHeaderTitleFontSize",
        "attributes": {
          "category": "typography",
          "type": "pageHeaderTitle",
          "item": "fontSize"
        },
        "path": [
          "typography",
          "pageHeaderTitle",
          "fontSize"
        ]
      },
      "fontWeight": {
        "value": "400",
        "original": {
          "value": "{font.weight.regular.value}"
        },
        "name": "typographyPageHeaderTitleFontWeight",
        "attributes": {
          "category": "typography",
          "type": "pageHeaderTitle",
          "item": "fontWeight"
        },
        "path": [
          "typography",
          "pageHeaderTitle",
          "fontWeight"
        ]
      },
      "letterSpacing": {
        "value": 0,
        "original": {
          "value": 0
        },
        "name": "typographyPageHeaderTitleLetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "pageHeaderTitle",
          "item": "letterSpacing"
        },
        "path": [
          "typography",
          "pageHeaderTitle",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "32px",
        "original": {
          "value": "32px"
        },
        "name": "typographyPageHeaderTitleLineHeight",
        "attributes": {
          "category": "typography",
          "type": "pageHeaderTitle",
          "item": "lineHeight"
        },
        "path": [
          "typography",
          "pageHeaderTitle",
          "lineHeight"
        ]
      }
    }
  }
};
export default theme;