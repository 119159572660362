/* eslint-disable indent */
// Running eslint fix causes the function flatChildren to break as it adds indentations where not needed

import React, {
  cloneElement,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { KEY_CODES, getPositionStyles, getDynamicPosition, flatMap, removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

import { useWindowWidth } from '../../utilities';
import { MENU_PLACEMENT } from '../constants';
import { StyledMenuContainer } from '../elements';
import { useOutsideClickEventListener } from '../utilities';

const StyledItemContainer = styled.div`
  padding: ${({ theme }) => theme.size.spacing.small.value} 0;
`;

const getScrollableParent = (node) => {
  if (node === null || node === undefined) {
    return null;
  } else if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollableParent(node.parentNode);
  }
};

const Menu = forwardRef(
  (
    {
      children,
      className,
      dataTestId,
      dimensionRef,
      isAuto,
      isOpen,
      labelRef,
      multi,
      onClose,
      onOpen,
      placement,
      style,
      triggerOffset,
      ...other
    },
    ref
  ) => {
    const [targetNode, setTargetNode] = useState(null);
    const focusItem = useRef(null);
    const [keyBoardFocus, setKeyBoardFocus] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const menuRef = useRef(document.createElement('div'));
    const { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE, SPACE, TAB } = KEY_CODES;
    const menuContainerRef = useRef();
    const menuOpenStatus = useRef(null);

    const flatChildren = Array.isArray(children)
      ? flatMap(children, (item) => {
          return Array.isArray(item) ? flatMap(item, (item) => item) : item;
        })
      : [children];
    const menuListRef = useRef(flatChildren.map(() => createRef()));

    // Following hook adds div to document body and assign node to menu
    useEffect(() => {
      document.body.appendChild(menuRef.current);
      setTargetNode(menuRef.current);
      setOpenMenu(isOpen);
    }, [isOpen]);

    // This section closes the Menu on screen resize
    const scanWindow = useWindowWidth(5);
    useLayoutEffect(() => {
      if (openMenu) {
        setOpenMenu(false);
      }
    }, [scanWindow]);

    const handleMenu = () => {
      setOpenMenu((openMenu) => !openMenu);
    };

    const handleClose = () => {
      menuOpenStatus.current = false;
      setOpenMenu(false);
      setKeyBoardFocus(0);
      labelRef.current.focus();
    };

    const setSelectedFocusStyles = (ref) => {
      if (!multi) {
        handleClose();
      }
      focusItem.current = ref;
    };

    //  Following hook adds click and keydown event handlers to Label element
    useEffect(() => {
      const labelElm = labelRef.current;
      if (labelElm) {
        labelElm.addEventListener('click', handleMenu);
        labelElm.addEventListener('keydown', handleLabelKeyDown);
      } else {
        console.error('Missing Label ref, labelRef should match label component ref ');
      }
      return () => {
        if (labelElm) {
          labelElm.removeEventListener('click', handleMenu);
          labelElm.removeEventListener('keydown', handleLabelKeyDown);
        }
      };
    }, [labelRef]);

    const handleLabelKeyDown = useCallback((event) => {
      if ([ARROW_UP, ARROW_DOWN].includes(event.keyCode)) {
        event.preventDefault();
        setOpenMenu(true);
        setKeyBoardFocus(event.keyCode);
        const focusedItem = focusItem.current;
        if (focusedItem) {
          focusedItem.current.focus();
        } else {
          const listItemRef = menuListRef.current;
          const itemsLength = listItemRef.length - 1;
          if (event.keyCode === ARROW_DOWN) {
            listItemRef[0].current.focus();
          } else if (event.keyCode === ARROW_UP) {
            listItemRef[itemsLength].current.focus();
          }
        }
      }
      if ([ESCAPE, TAB].includes(event.keyCode)) {
        handleClose();
      }
      if ([ENTER, SPACE].includes(event.keyCode)) {
        event.preventDefault();
        handleMenu();
      }
    });

    let initialRender = true;

    useEffect(() => {
      if (onOpen && openMenu) {
        onOpen();
      }
      if (initialRender && onClose && !openMenu) {
        initialRender = false;
        onClose();
      }
      if (openMenu) {
        menuOpenStatus.current = true;
      }
      const scrollParent = getScrollableParent(labelRef.current);
      if (labelRef.current) {
        document.addEventListener('scroll', () => {
          if (menuOpenStatus.current) {
            handleClose();
          }
        });
        // eslint-disable-next-line mdx/no-unused-expressions
        !!scrollParent &&
          scrollParent.addEventListener('scroll', () => {
            if (menuOpenStatus.current) {
              handleClose();
            }
          });
      }
      const focusedItem = focusItem.current;
      if (focusedItem) {
        focusedItem.current.focus();
      } else {
        const listItemRef = menuListRef.current;
        const itemsLength = listItemRef.length - 1;
        if (keyBoardFocus === ARROW_DOWN) {
          listItemRef[0].current.focus();
        } else if (keyBoardFocus === ARROW_UP) {
          listItemRef[itemsLength].current.focus();
        }
      }
      return () => {
        if (labelRef.current) {
          document.removeEventListener('scroll', handleClose);
          // eslint-disable-next-line mdx/no-unused-expressions
          !!scrollParent && scrollParent.removeEventListener('scroll', handleClose);
        }
      };
    }, [openMenu]);

    const listItemKeyDown = useCallback((event, target = null) => {
      const eventTarget = target || event.target;
      if (event.keyCode === ARROW_DOWN) {
        event.preventDefault();
        if (eventTarget && eventTarget.nextSibling) {
          if (eventTarget.nextSibling.getAttribute('disabled') !== null) {
            return listItemKeyDown(event, eventTarget.nextSibling);
          }
          eventTarget.nextSibling.focus();
        } else {
          menuListRef.current[0].current.focus();
        }
      }
      if (event.keyCode === ARROW_UP) {
        event.preventDefault();
        if (eventTarget && eventTarget.previousElementSibling) {
          if (eventTarget.previousElementSibling.getAttribute('disabled') !== null) {
            return listItemKeyDown(event, eventTarget.previousElementSibling);
          }
          eventTarget.previousElementSibling.focus();
        } else {
          const listLength = menuListRef.current.length;
          menuListRef.current[listLength - 1].current.focus();
        }
      }
      if ([ESCAPE, TAB].includes(event.keyCode)) {
        handleClose();
      }
    });

    useEffect(() => {
      // eslint-disable-next-line array-callback-return
      menuListRef.current.map((el) => {
        if (el.current) {
          el.current.addEventListener('keydown', listItemKeyDown);
        }
      });
      return () => {
        // eslint-disable-next-line array-callback-return
        menuListRef.current.map((el) => {
          if (el.current) {
            el.current.removeEventListener('keydown', listItemKeyDown);
          }
        });
      };
    }, [listItemKeyDown, openMenu]);

    useOutsideClickEventListener(menuRef, labelRef, handleClose, openMenu);

    const isKeyboardFocused = keyBoardFocus !== 0;

    const position = isAuto
      ? getDynamicPosition(placement, menuContainerRef, dimensionRef || labelRef, openMenu, triggerOffset)
      : placement;
    const styles = getPositionStyles(position, triggerOffset, dimensionRef || labelRef, openMenu, menuContainerRef);
    const isStyleNull = isNaN(styles.top) && isNaN(styles.left);
    const finalStyles = isStyleNull ? removeObjectProperties(styles, 'top', 'left') : styles;
    return (
      <>
        {openMenu &&
          createPortal(
            <StyledMenuContainer
              style={style ? { ...style, ...finalStyles } : finalStyles}
              ref={menuContainerRef}
              data-testid={dataTestId}
              className={className}
              {...other}
            >
              <StyledItemContainer>
                {flatChildren.length > 0 &&
                  flatChildren.map((child, ind) => {
                    return cloneElement(child, {
                      ref: menuListRef.current[ind],
                      key: ind.toString(),
                      tabIndex: 0,
                      isKeyboardFocused: isKeyboardFocused,
                      showCheckbox: !!multi,
                      onSelect: setSelectedFocusStyles,
                    });
                  })}
              </StyledItemContainer>
            </StyledMenuContainer>,
            targetNode
          )}
      </>
    );
  }
);

Menu.propTypes = {
  /** the Ref of the wrapper element  */
  labelRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }.isRequired),
  /** Menu items to populate menu */
  children: PropTypes.array.isRequired,
  /** Adds new class to Menu */
  className: PropTypes.string,
  /** the Ref of the element relative to which the menu will be positioned */
  dimensionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** Call back function will trigger after opening Menu */
  onOpen: PropTypes.func,
  /** Call back function will trigger after closing Menu */
  onClose: PropTypes.func,
  /** If true, switch automatically to a placement that is more visible on the screen */
  isAuto: PropTypes.bool,
  /** Flag to open/close menu */
  isOpen: PropTypes.bool,
  /** Preferred placement of the menu */
  placement: PropTypes.oneOf(Object.values(MENU_PLACEMENT)),
  /** Custom inline style applied for the menu */
  style: PropTypes.shape({}),
  /** Parameter defining space between the target component and popup. */
  triggerOffset: PropTypes.number,
  /** Flag to enable multi selection */
  multi: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
  dimensionRef: undefined,
  isAuto: true,
  placement: MENU_PLACEMENT.BOTTOM_START,
  style: {},
  triggerOffset: 4,
  dataTestId: '',
  multi: false,
  isOpen: false,
  onClose: undefined,
  onOpen: undefined,
};

export { Menu };
