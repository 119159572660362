import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS, getRem } from '../../core';
import { AVATAR_SIZES } from '../constants';
import { getFirstLetter } from '../utilities';

const StyledAvatarText = styled.span`
  ${({ size, theme }) => css`
    color: ${theme.color.additional.light.value};
    line-height: ${size === AVATAR_SIZES.STANDARD ? getRem(32) : getRem(24)};
    letter-spacing: ${size === AVATAR_SIZES.STANDARD ? '0' : getRem(0.15)};
    font-family: ${theme.font.family.main.value};
    font-size: ${size === AVATAR_SIZES.STANDARD ? getRem(24) : getRem(18)};
    font-weight: ${size === AVATAR_SIZES.STANDARD ? 400 : 500};
    ${size === AVATAR_SIZES.RESPONSIVE &&
    css`
      font-size: ${getRem(24)};
      font-weight: 400;
      @media ${BREAKPOINTS.L} {
        font-size: ${getRem(18)};
        font-weight: 500;
      }
    `}
  `}
`;

const AvatarText = ({ label, size, ...other }) => (
  <StyledAvatarText size={size} {...other}>
    {getFirstLetter(label)}
  </StyledAvatarText>
);

AvatarText.propTypes = {
  /** Avatar label */
  label: PropTypes.node.isRequired,
  /** Avatar wrapper size. Text size changes depending on wrapper size */
  size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
};

AvatarText.defaultProps = {
  size: AVATAR_SIZES.STANDARD,
};

export { AvatarText };
